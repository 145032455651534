import React, { useState } from "react";
import Accordion from "~/components/accordion";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import Button from "~/components/button";
import { FlexRow } from "~/content/styledComponents";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={2}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 2</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>Watch 2 commercials for different medications. As you watch the commercials, think about the types of information you hear.</p>
            <p>Are there any similarities in the type of information in the 2 ads? Do you notice any differences? Write your ideas in your science notebook. Watch the commercials again if you wish.</p>
            <div className="mt-5">
              <FlexRow>
                <Button
                  action="secondary"
                  color="purple"
                  href="https://www.ispot.tv/ad/ASVF/stelara-which-you"
                  icon="external"
                  title="commercial 1"
                >
                  Commercial 1
                </Button>
                <Button
                  action="secondary"
                  color="purple"
                  href="https://www.ispot.tv/ad/Ipli/culturelle-finally"
                  icon="external"
                  title="commercial 2"
                  >
                  Commercial 2
                </Button>
              </FlexRow>
            </div>
            <div className="mt-4">
              <Accordion headerText="Hint" open={false}>
                <p>You do not need to know specific details about the health issues in the commercials. Just look for trends in the kind of information they give.</p>
              </Accordion>
            </div>
            <div className="mt-4">
              <Accordion headerText="Optional information" open={false}>
                <p>Psoriasis (sor-EYE-uh-sis) is a chronic skin disease. People who have the disease have patches of thick, red skin and silvery scales. Patches are often found on the elbows, knees, scalp, lower back, face, palms, and soles of the feet, but can also be other places on the body. Psoriasis occurs because the growth of skin cells speeds up.</p>
              </Accordion>
            </div>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Students watch and compare 2 TV commercials. At this stage, students should be comparing the ads at a general level and given their first impressions of what they see and hear. The main thing students should notice is that the ad for the prescription drug (Stelara®) includes information about the desired outcomes of using the drug and the potential for negative outcomes. The other ad, for an over-the-counter medication, provides much less information and focuses on the positive outcomes the user might experience.</p>
            <p>At this point, students do not need to identify specific ideas or phrases in the ads that relate to positive or negative outcomes. They do not need to know specific information about the conditions mentioned in the ads or what the drugs do. They should simply compare the two ads and notice that one includes much more information than the other.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

